.Collapsible__trigger {
  display: block;
  color: white;
  position: relative;
  border-bottom: 1px solid #ffffff;
  /* padding: 12px 0px 6px 0px; */
  margin: 22px 0px 6px 0px;
  cursor: pointer;
}
.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  content: '\f107';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
